import React, { useEffect, useRef } from 'react';

const CanvasBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let stars = [];

    // Function to create stars
    const createStars = () => {
      for (let i = 0; i < 100; i++) {  // 100 stars
        stars.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          radius: Math.random() * 1.5,
          alpha: Math.random() * 1
        });
      }
    };

    // Function to draw stars
    const drawStars = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Create gradient background
      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      gradient.addColorStop(0, 'rgba(30, 30, 30, 1)'); // Dark gray
      gradient.addColorStop(1, 'rgba(0, 0, 0, 1)');    // Black

      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw each star
      for (let star of stars) {
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(255, 255, 255, ${star.alpha})`;  // White stars with varying alpha
        ctx.fill();
      }
    };

    // Function to update stars' alpha for twinkling effect
    const twinkleStars = () => {
      for (let star of stars) {
        star.alpha += (Math.random() * 0.02 - 0.01);  // Subtle twinkling
        if (star.alpha < 0) star.alpha = 0;
        if (star.alpha > 1) star.alpha = 1;
      }
    };

    // Animation loop
    const animate = () => {
      twinkleStars();
      drawStars();
      requestAnimationFrame(animate);
    };

    // Resize canvas and recreate stars on window resize
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      stars = [];  // Clear stars array
      createStars();  // Recreate stars
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();  // Initialize canvas size and stars
    animate();      // Start animation

    return () => window.removeEventListener('resize', resizeCanvas);
  }, []);

  return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, zIndex: -1 }} />;
};

export default CanvasBackground;
