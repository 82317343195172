import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const navLinks = [
  { path: "/djing", label: "DJing" },               // DJing on top
  { path: "/discogs-collection", label: "Discogs Collection" },
  { path: "/discogs-api", label: "Discogs API" },
  { path: "/no-dogs-allowed", label: "No Dogs Allowed" },
  { path: "/github", label: "GitHub" },
  { path: "/linkedin", label: "LinkedIn" }           // LinkedIn at the bottom
];

const renderNavItem = (path, label) => (
  <li className="nav-item">
    <Link className="nav-link" to={path}>{label}</Link>
  </li>
);

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">Nathan's Links</Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {navLinks.map((link, index) => renderNavItem(link.path, link.label))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;